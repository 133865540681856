import React from "react";

import { cross, opened, lineBox, line, vertical, horizontal } from './cross.module.scss';

interface Props {
    onClick(): any,
    open: boolean,
}

const Cross: React.FC<Props> = ({onClick, open = false}) => {
    return (
        <button
            onClick={() => onClick()}
            className={`${cross} ${open ? opened : ''}`}
            aria-label={open ? 'Close menu' : 'Open menu'}
        >
            <span className={lineBox}>
                <span className={`${line} ${vertical}`} />
                <span className={`${line} ${horizontal}`} />
            </span>
        </button>
    )
}

export default Cross;