import { Answer } from "schema-dts";

import { IAnswer } from "../../models/structured-data.model";

export default function createAnswer(answer: IAnswer): Answer {
    const { text } = answer;

    return {
        '@type': 'Answer',
        text,
    }
}