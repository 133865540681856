import { Question } from "schema-dts";

import { IQuestion } from "../../models/structured-data.model";
import createAnswer from "./create-answer";

export default function createQuestion(question: IQuestion): Question {
    const { name, acceptedAnswer } = question;

    return {
        '@type': 'Question',
        name,
        acceptedAnswer: createAnswer(acceptedAnswer)
    }
}