import React, { useCallback, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { FAQPage } from "schema-dts";

import { mainSection, heading, text, faqSection } from "./faq.module.scss";
import QueryEdgeNode from "../../models/query-edge-node.model";
import { IPageBase } from "../../models/page-base.model";
import { IQuestion } from "../../models/structured-data.model";
import { ITitleProps } from "../atoms/title";
import usePageMetadata from "../../hooks/use-page-metadata";
import createFAQPage from "../../utils/structured-data/create-faq-page";

import SingleFaq, { ISingleFaq, ISingleFaqProps } from "../molecules/single-faq";
import MainLayout from "../../layouts/main-layout";
import Section from "../molecules/section";

export interface IFaqProps extends IPageBase {
    readonly data: {
        allFaq: QueryEdgeNode<ISingleFaq>;
    };
}

const Faq: React.FC<IFaqProps> = ({ data, pageContext }) => {
    const titleProps: ITitleProps = {
        subtitle: "faq",
        size: "large",
        className: heading,
        children: <span>Najczęściej zadawane pytania</span>,
    };

    const [questions, setQuestions] = useState<Record<string, IQuestion>>({});
    const [structuredData, setStructuredData] = useState<FAQPage | undefined>();

    const { allFaq } = data;

    const { pageTitle } = usePageMetadata(pageContext);

    const handleCreateQuestion: ISingleFaqProps["onCreateQuestion"] = useCallback((question) => {
        setQuestions((prevState) => ({
            ...prevState,
            [question.name]: question,
        }));
    }, []);

    useEffect(() => {
        setStructuredData(createFAQPage(Object.values(questions)));
    }, [questions]);

    return (
        <MainLayout
            SEOProps={{
                title: pageTitle,
                structuredData,
            }}
        >
            <Section
                column={"left"}
                columnTablet={"regular"}
                columnPhone={"narrow"}
                title={titleProps}
                className={mainSection}
            >
                <div className={text}>
                    Jeśli coś jest dla Ciebie niejasne, to tu znajdziesz odpowiedź na najczęściej
                    zadawane pytania.
                </div>
            </Section>

            <Section
                column={"full-right"}
                columnTablet={"full"}
                columnPhone={"full"}
                className={faqSection}
            >
                {allFaq.edges.map(({ node }, index) => {
                    return (
                        <SingleFaq
                            key={`single-faq-${index}`}
                            data={node}
                            onCreateQuestion={handleCreateQuestion}
                        />
                    );
                })}
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allFaq {
            edges {
                node {
                    translations {
                        pl {
                            question
                            answer
                        }
                    }
                }
            }
        }
    }
`;

export default Faq;
