import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import AnimateHeight from 'react-animate-height';

import {faq, questions, answers} from './single-faq.module.scss';
import {config} from '../../config';
import {useTranslation} from "../../hooks/use-translation";
import { IQuestion } from "../../models/structured-data.model";

import Cross from "../atoms/cross";

const {translationKeys} = config;

export interface ISingleFaq {
    translations: {
        [key: string]: {
            question?: string,
            answer?: string,
        }
    }
}

export interface ISingleFaqProps {
    data: ISingleFaq,
    onCreateQuestion?: (question: IQuestion) => void
}

const SingleFaq: React.FC<ISingleFaqProps> = ({
    data,
    onCreateQuestion = () => {}
}) => {

    const {question, answer} = useTranslation(data, translationKeys.singleFaq);
    const [isFaqOpen, setIsFaqOpen] = useState(false);

    useEffect(() => {
        onCreateQuestion({
            name: question,
            acceptedAnswer: {
                text: answer
            }
        });
    }, [onCreateQuestion, question, answer]);

    return (
        <div className={faq} onClick={() => setIsFaqOpen(!isFaqOpen)}>
            <div className={questions}>{question}</div>

            <AnimateHeight className={answers} duration={500} height={isFaqOpen ? 'auto' : 0}>
                <ReactMarkdown
                    // @ts-ignore
                    rehypePlugins={[rehypeRaw]}>
                    {answer}
                </ReactMarkdown>
            </AnimateHeight>
            <Cross
                onClick={() => setIsFaqOpen(!isFaqOpen)}
                open={isFaqOpen}
            />
        </div>
    )
}

export default SingleFaq;